<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-row>
            <v-col cols="12">
              <v-img
                  height="70"
                  src="@/assets/images/zmyle.png"
                  contain
                  style="margin-top: 30px; margin-bottom: 30px;"
              ></v-img>
            </v-col>
          </v-row>

          <v-toolbar color="pink darken-2" flat>
            <v-toolbar-title class="text-white">{{ $t('settings.lang_zmyleInterface') }}</v-toolbar-title>

            <v-spacer></v-spacer>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="apiKey" :disabled="loading" outlined dense :label="$t('settings.lang_apiKey')"></v-text-field>
                <v-text-field v-model="apiSecret" :disabled="loading" type="password" outlined dense :label="$t('settings.lang_apiSecret')"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" :disabled="loading" :loading="loading" @click="saveSettings">{{ $t('generic.lang_save') }}</v-btn>
          </v-card-actions>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>


import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "ZmyleSettings",
  data() {
    return {
      loading: false,
      apiSecret: "",
      apiKey: "",
    }
  },
  mounted() {
    this.getSettings();
  },

  methods: {
    getSettings() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.MODULESETTINGS.ZMYLE.GET).then((res) => {
        if(res.data.status==='success') {
          this.apiSecret = res.data.apiSecret;
          this.apiKey = res.data.apiKey;
        }
        else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    saveSettings() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.MODULESETTINGS.ZMYLE.UPDATE, {
        apiSecret: this.apiSecret,
        apiKey: this.apiKey
      }).then((res) => {
        if(res.data.status==='SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
        }
        else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).finally(() => {
        this.loading = false;
      });
    },
  }

}
</script>